import React from 'react';
import DescribeIdea from './DescribeNewProduct';
import Benefits from './Benefits';
import Issues from './Issues/index';

import './styles.css';

const contradictionModalTabs = ({ currentTab, ...props }) => {
    return (
        <>
            {currentTab === 0 && (
                <DescribeIdea
                    selectCurrentcontradictionImageFromLibrary={props.selectCurrentcontradictionImageFromLibrary}
                    removeImageFromLibrary={props.removeImageFromLibrary}
                    handleAcceptPotentialImage={props.handleAcceptPotentialImage}
                    contradiction={props.contradiction}
                    potentialGeneratedImageUrl={props.potentialGeneratedImageUrl}
                    handleChange={props.handleChange}
                    uploadInfo={props.uploadInfo}
                    newGeneratedImage={props.newGeneratedImage}
                    handleCancelClick={props.handleCancelClick}
                    handleFileSelect={props.handleFileSelect}
                    isLoadingImage={props.isLoadingImage}
                    handleGenerateNames={props.handleGenerateNames}
                    openGenerateNameModal={props.openGenerateModal}
                    closeGenerateNamesModal={props.closeGenerateModal}
                    suggestedNamesForIdeas={props.suggestedOptions}
                    suggestedLoading={props.suggestedLoading}
                    addIdeasName={props.addIdeasName}
                    handleGenerateImage={props.handleGenerateImage}
                    isOpenGenerateImageModal={props.isOpenGenerateImageModal}
                    closeDialogGenerateImage={props.closeDialogGenerateImage}
                    generatedImage={props.generatedImage}
                    generateContradictionSolving={props.generateContradictionSolving}
                />
            )}

            {currentTab === 1 && (
                <Benefits
                    benefits={props.contradiction.potentialBenefitsOfContradictionSolving}
                    addBenefit={props.addBenefit}
                    removeBenefit={props.removeBenefit}
                    removeLastBenefit={props.removeLastBenefit}
                    handleBenefitChange={props.handleBenefitChange}
                    handleTargetChange={props.handleTargetChange}
                    customCategories={props.customCategories}
                    score={props.contradiction.score}
                    handleChangeScore={props.handleChangeScore}
                    handleGenerateBenefits={props.handleGenerateBenefits}
                    openGenerateBenefitsModal={props.openGenerateModal}
                    closeGenerateBenefitsModal={props.closeGenerateModal}
                    suggestedLoading={props.suggestedLoading}
                    suggestedBenefits={props.suggestedOptions}
                    addGenerateBenefits={props.addGenerateBenefits}
                    selectGenerateOption={props.selectGenerateOption}
                    selectedGenerateOptions={props.selectedGenerateOptions}
                    userFullName={props.userFullName}
                    descriptionIdea={props.contradiction.description}
                    handleDeleteOption={props.handleDeleteOption}
                    handleRenameOption={props.handleRenameOption}
                    addCustomCategory={props.addCustomCategory}
                />
            )}

            {currentTab === 2 && (
                <Issues
                    issues={props.contradiction.issues}
                    userId={props.userId}
                    userFullName={props.userFullName}
                    messages={props.contradiction.issuesMessages}
                    addIssue={props.addIssue}
                    removeIssue={props.removeIssue}
                    removeLastIssue={props.removeLastIssue}
                    removeMessage={props.removeMessage}
                    removeLastMessage={props.removeLastMessage}
                    handleChange={props.handleIssueChange}
                    addIssueMessage={props.addIssueMessage}
                    handleIssueMessageChange={props.handleIssueMessageChange}
                    handleChangeFeasibleScore={props.handleChangeFeasibleScore}
                    feasibleScore={props.contradiction.feasibleScore}
                    handleGenerateChallenges={props.handleGenerateChallenges}
                    openGenerateChallengesModal={props.openGenerateModal}
                    closeGenerateChallengesModal={props.closeGenerateModal}
                    suggestedChallenges={props.suggestedOptions}
                    suggestedLoading={props.suggestedLoading}
                    addGenerateChallenges={props.addGenerateChallenges}
                    selectGenerateOption={props.selectGenerateOption}
                    selectedGenerateOptions={props.selectedGenerateOptions}
                    descriptionIdea={props.contradiction.description}
                />
            )}
        </>
    );
};

export default contradictionModalTabs;
